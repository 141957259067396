<template>
  <div v-if="isOnboardingInProgress" class="w-full py-3 py relative">
    <div
      class="h-full w-fit px-5 bg-primary flex items-center justify-center rounded-l-xl z-10 absolute left-0 top-0"
    >
      <Icon :icon="InfoSvg" class="text-on-primary" />
    </div>
    <div
      class="relative z-20 pl-20 pr-10 h-full w-full flex gap-4 md:gap-2 items-center flex-wrap justify-center md:justify-between"
    >
      <span class="md:text-left">
        {{ $t('onboarding.banner.message') }}
      </span>
      <Btn
        to="/onboarding"
        :color="BtnColor.PRIMARY"
        :variant="BtnVariant.FILLED"
      >
        {{ $t('onboarding.banner.complete') }}
      </Btn>
    </div>

    <div
      class="bg-primary opacity-10 w-full h-full absolute left-0 top-0 rounded-xl z-10"
    />
    <div class="bg-[white] w-full h-full absolute left-0 top-0 rounded-xl" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useOrganizationStore } from '~/store/organization';
import { Icon } from '~/components/icon';
import { Btn, BtnColor, BtnVariant } from '~/components/btn';
import InfoSvg from '~/assets/icons/info.svg?component';
import { useAccountStore } from '~/store/account';

const { organization } = storeToRefs(useOrganizationStore());
const { currentAccount } = storeToRefs(useAccountStore());

const isOnboardingInProgress = computed(
  () =>
    organization.value?.isCoOp &&
    currentAccount.value?.currentlySelectedBusiness?.coOpOnboarding &&
    !currentAccount.value?.currentlySelectedBusiness.coOpOnboarding.isCompleted,
);
</script>
